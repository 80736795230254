import menu from '../Data/Menu.json'
import './MenuListStyle.css'
import { useEffect, useState } from 'react'
import hamburgerIcon from '../Data/HamburgerIcon.png'
import hamburgerIconx from '../Data/HamburgerIconX.png'
function MenuList(params) {
    const [hamburger, setHamburger] = useState(true)
    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        let timerID = setInterval(() => {
            setWidth(window.innerWidth);
            if (window.innerWidth>835){
                setHamburger(false)
            }
        }, 100)
        return () => {
            clearInterval(timerID)
        }
    })
    const handleClick = () => {
        setHamburger(!hamburger)
    }

    return (<div className="MenuList">
        <ul>
            {width > 835 && <>

                {menu.map(item => (<a key={String(item.Title)} onClick={() => params.parentCallBack(item.Link)} >{item.Title}</a>))}
            </>}
            {hamburger && width <= 835 && <>
                <img src={hamburgerIconx} alt="hamburger" className='Hamburger' onClick={handleClick}></img>
                {menu.map(item => (<a key={String(item.Title)} onClick={() => params.parentCallBack(item.Link)}>{item.Title}</a>))}
            </>}
            {!hamburger && <>
                <img src={hamburgerIcon} alt="hamburger" className='Hamburger' onClick={handleClick}></img>
            </>}
        </ul>
    </div>)
}

export default MenuList;