import {BrowserRouter,  Route, Routes } from 'react-router-dom';
import './App.scss';
import Menu from './Menu/Menu';
import ToddFooter from './ToddFooter/ToddFooter';
import Bid from './Bid/Bid';
import ContactUs from './ContactUs/ContactUs';
import PhotoGallery from './HousePlans/PhotoGallery'
import AboutUs from './AboutUs/AboutUs';
import Home from './Home/Home'
import HomeGallery from './HomeGallery/HomeGallery';
import Scholarship from './Scholarship/Scholarship';
import { useState } from 'react';

function App() {
  const[page,setPage]= useState("/")
  return (
    <div className="App">
      <Menu parentCallBack={setPage}/>
      {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/Bid" element={<Bid/>}/>
          <Route path="/ContactUS" element={<ContactUs/>}/>
          <Route path="/HousePlans" element={<PhotoGallery/>}/>
          <Route path="/PhotoGallery" element={<PhotoGallery/>}/>
          <Route path="/AboutUs" element={<AboutUs/>}/>
          <Route path="/Portfolio" element={<HomeGallery/>}/>
          <Route path="/Scholarships" element={<Scholarship/>}/>
        </Routes>
      </BrowserRouter> */}
      {page==="/"&&
      <Home parentCallBack={setPage}></Home>
      }
      {page==="/Bid"&&
      <Bid></Bid>
      }
      {page==="/ContactUs"&&
      <ContactUs></ContactUs>
      }
      {page==="/HousePlans"&&
      <PhotoGallery></PhotoGallery>
      }
      {page==="/AboutUs"&&
      <AboutUs></AboutUs>
      }
      {page==="/Portfolio"&&
      <HomeGallery></HomeGallery>
      }
      {page==="/Scholarships"&&
      <Scholarship></Scholarship>

      }
      <ToddFooter/>
    </div>
  );
}

export default App;
