import './Scholarship.css'
import pic from '../Data/TACScholarship.PNG'

export default function Scholarship(params) {
    return (<>
        <div className="Scholarship">
            <div className="ScholoashipBanner"><h1>Todd Alder Construction
                Scholarship Program</h1></div>
            <h2>2025 SCHOLARSHIP INFORMATION</h2>
            <div className="scholarshipContainer">

                <div className='ScholarshipLeftColumn'>
                    <div className='ScholarshipInfoText'>
                        <p>Todd Alder Construction, located in Ephraim, UT,
                            offers scholarships to high school seniors in
                            Sanpete County who plan to pursue a post-secondary education that is intended to lead to
                            a career in the construction industry.
                            Scholarships will be awarded based on academic
                            achievement, community/volunteer activities,
                            school activities, leadership, awards/honors,
                            work experience and financial needs. Awards
                            are granted without regard to race, creed, color,
                            religion, gender, sexual orientation or national
                            origin.</p>
                    </div>
                    <div className='ScholarshipInfoText'>
                        <h3>Am I Eligible to Apply?</h3>
                        <p>To be eligible, you must:</p>
                        <ol>
                            <p>
                                <li>Be a high school senior</li>
                            </p>
                            <p>
                                <li>Attend high school AT Gunnison, Manti, or
                                    North Sanpete high school</li>
                            </p>
                            <p>
                                <li>Maintained a cumulative GPA of at least 2.7
                                    throughout high school</li>
                            </p>
                            <p>
                                <li>Demonstrate a strong interest in pursuing a
                                    career in the construction industry</li>
                            </p>
                            <p>
                                <li>Plan to attend a post-secondary institution to
                                    earn a certificate or degree in the
                                    construction industry
                                </li>
                            </p>
                        </ol>
                    </div>
                    <div className='ScholarshipInfoText large'>
                        <h3>Application Deadline</h3>
                        <p>The application form must be completed and
                            submitted online by April 15, 2025. The
                            application can be found <br></br><a href="https://docs.google.com/forms/d/e/1FAIpQLSd-imkPlpFRyYDpoEsiqbFlgc6zwaG43USJ_Jd6UMsuJq1Q5g/viewform?usp=sharing" target="_blank" rel="noopener noreferrer">Start Scholarship Applicatiion!</a></p>
                    </div>
                </div>


                <div className='ScholarshipRightColumn'>
                    <div className='ScholarshipInfoText'>
                        <h3>Selection Criteria</h3>
                        <p>Scholarship Selection criteria include:</p>
                        <ul>
                            <p>
                                <li>Academic achievement and potential</li>
                            </p>
                            <p>
                                <li>Community/volunteer activities</li>
                            </p>
                            <p>
                                <li>School activities</li>
                            </p>
                            <p>
                                <li>Leadership</li>
                            </p>
                            <p>
                                <li>Awards/honors</li>
                            </p>
                            <p>
                                <li>Work experience</li>
                            </p>
                            <p>
                                <li>Financial needs</li>
                            </p>
                        </ul>
                    </div>
                    <div className='ScholarshipInfoText'>
                        <h3>Award Information</h3>
                        <p>Scholars will receive a $500 scholarship check.
                            Award checks will be issued with a scholarship
                            certificate.</p>
                    </div>
                    <div className='ScholarshipInfoText'>
                        <h3>Notifications</h3>
                        <p>All applicants will be notified of their award
                            status at their high school’s awards ceremony.</p>
                    </div>
                    <div className='ScholarshipInfoText'>
                        <h3>Questions</h3>
                        <p>If you have questions, talk to your high school
                            counselor or call Todd Alder Construction at
                            435-283-8612.</p>
                    </div>
                    <div className='ScholarshipInfoText small'>
                        <h3>Application Deadline</h3>
                        <p>The application form must be completed and
                            submitted online by April 15, 2025. The
                            application can be found <br></br><a href="https://docs.google.com/forms/d/e/1FAIpQLSd-imkPlpFRyYDpoEsiqbFlgc6zwaG43USJ_Jd6UMsuJq1Q5g/viewform?usp=sharing" target="_blank" rel="noopener noreferrer">Start Scholarship Applicatiion!</a></p>
                    </div>
                    
                    <div className='ScholarshipInfoText'><img src={pic} alt="Todd Alder Construction Scholarship"></img> </div>
                </div>
            </div>

            {/* 
            <div className='ScholarshipInfo'>
                <div className='ScholarshipInfoText'>
                    <p>Todd Alder Construction, located in Ephraim, UT,
                        offers scholarships to high school seniors in
                        Sanpete County who plan to pursue a post-secondary education that is intended to lead to
                        a career in the construction industry.
                        Scholarships will be awarded based on academic
                        achievement, community/volunteer activities,
                        school activities, leadership, awards/honors,
                        work experience and financial needs. Awards
                        are granted without regard to race, creed, color,
                        religion, gender, sexual orientation or national
                        origin.</p>
                </div>
                <div className='ScholarshipInfoText'>
                    <h3>Selection Criteria</h3>
                    <p>Scholarship Selection criteria include:</p>
                    <ul>
                        <p>
                            <li>Academic achievement and potential</li>
                        </p>
                        <p>
                            <li>Community/volunteer activities</li>
                        </p>
                        <p>
                            <li>School activities</li>
                        </p>
                        <p>
                            <li>Leadership</li>
                        </p>
                        <p>
                            <li>Awards/honors</li>
                        </p>
                        <p>
                            <li>Work experience</li>
                        </p>
                        <p>
                            <li>Financial needs</li>
                        </p>
                    </ul>
                </div>
                <div className='ScholarshipInfoText'>
                    <h3>Award Information</h3>
                    <p>Scholars will receive a $500 scholarship check.
                        Award checks will be issued with a scholarship
                        certificate.</p>
                </div>

                <div className='ScholarshipInfoText'>
                    <h3>Am I Eligible to Apply?</h3>
                    <p>To be eligible, you must:</p>
                    <ol>
                        <p>
                            <li>Be a high school senior</li>
                        </p>
                        <p>
                            <li>Attend high school AT Gunnison, Manti, or
                                North Sanpete high school</li>
                        </p>
                        <p>
                            <li>Maintained a cumulative GPA of at least 2.7
                                throughout high school</li>
                        </p>
                        <p>
                            <li>Demonstrate a strong interest in pursuing a
                                career in the construction industry</li>
                        </p>
                        <p>
                            <li>Plan to attend a post-secondary institution to
                                earn a certificate or degree in the
                                construction industry
                            </li>
                        </p>
                    </ol>
                </div>

                <div className='ScholarshipInfoText'>
                    <div className='sub'>
                        <h3>Notifications</h3>
                        <p>All applicants will be notified of their award
                            status at their high school’s awards ceremony.</p>
                    </div>
                    <div className='sub'>
                        <h3>Questions</h3>
                        <p>If you have questions, talk to your high school
                            counselor or call Todd Alder Construction at
                            435-283-8612.</p>
                    </div>
                </div>
                <div classbName='sub'><img src={pic} alt="Todd Alder Construction Scholarship"></img> </div>
                <div className='ScholarshipInfoText'>
                    <h3>Application Deadline</h3>
                    <p>The application form must be completed and
                        submitted online by April 15, 2025. The
                        application can be found <br></br><a href="https://docs.google.com/forms/d/e/1FAIpQLSd-imkPlpFRyYDpoEsiqbFlgc6zwaG43USJ_Jd6UMsuJq1Q5g/viewform?usp=sharing" target="_blank" rel="noopener noreferrer">Start Scholarship Applicatiion!</a></p>
                </div>

            </div> */}
        </div>
    </>
    )
}